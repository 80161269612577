<template>
	<div class="login-page">
		<img v-if="isCRM" src="https://cdn.dkycn.cn/images/melyshop/2024091213520002.jpg" style="width: 100%;height: 100%" class="bg">
		<img v-if="!isCRM" src="https://cdn.dkycn.cn/images/melyshop/login-bg-1.png" style="width: 100%;height: 100%" class="bg">
		<div v-if="!isCRM" class="bg1">
			<img src="https://cdn.dkycn.cn/images/melyshop/login-bg-2.png">
			<div class="txt">
				<div class="txt1">企店·私域运营工具</div>
				<div class="txt2">做私域用企店<span style="color: #409EEF;">免费易用</span></div>
			</div>
		</div>
		<img v-if="!isCRM" src="https://cdn.dkycn.cn/images/melyshop/login-bg-3.png" class="bg2">
		
		<div v-if="!isCRM" class="bg3">
			<a href="https://open.work.weixin.qq.com" target="_blank"><img src="https://open.work.weixin.qq.com/service/img?id=wweace29825590581f&t=isp&c=white&s=medium" srcset="https://open.work.weixin.qq.com/service/img?id=wweace29825590581f&t=isp&c=white&s=medium@2x 2x" referrerpolicy="unsafe-url" alt="企业微信"></a>
		</div>

		<div class="login-wraper" :class="{'login-wraper-crm': isCRM}">
			<div class="login-tabs">
				<div class="login-tab" :class="{current: item.type == loginType}" v-for="(item, index) in loginTypeList"
					:key="index" @click="loginType=item.type">
					{{item.label}}
				</div>
			</div>

			<div class="login-item">
				<el-input v-model="account" placeholder="请输入登录手机号" autocomplete="on"></el-input>
			</div>
			<div class="login-item">
				<el-input v-model="code" type="text" placeholder="请输入验证码"></el-input>
				<div class="forget" @click="getSmsCode" v-if="!second">获取验证码</div>
				<div class="forget" v-else>{{second}}s</div>
			</div>
			<div class="login-item">
				<el-input v-model="password" :type="openPassword?'text':'password'" autocomplete="on"
					placeholder="请输入新密码(6~16位，可输入字母或数字)"></el-input>
				<div class="icon" @click="openPassword=!openPassword">
					<img src="@/assets/img/no_eye.png" v-show="!openPassword">
					<img src="@/assets/img/eye.png" v-show="openPassword">
				</div>
			</div>
			

			<el-button type="primary" size="large" style="margin-left: 50px;margin-top: 60px;width: 450px;height: 48px;font-size: 18px;"
				@click="handleForget">确认修改</el-button>

			<div class="login-tip">
				<el-link type="primary" style="font-size: 16px;" @click="handleToLogin">返回登录</el-link>
			</div>

		</div>

		<el-dialog :visible.sync="showImgCode" width="540px" title="请先完成图片验证" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
			<el-form ref="form" label-width="110px">
				<el-form-item label="图片验证码:">
					<div class="form-wraper">
						<el-input v-model="imgCode" style="width: 200px;"></el-input>
						<img :src="imgCodeUrl">
					</div>
					<div class="form-tip">
						<el-link type="primary" @click="getImgCode">看不清？换一张</el-link>
					</div>
				</el-form-item>
			</el-form>
			
			<div slot="footer" class="dialog-footer">
			      <el-button @click="showImgCode = false;imgCode=''">取 消</el-button>
			      <el-button type="primary" @click="getSmsCode">确 认</el-button>
			    </div>
		</el-dialog>

	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex'
	import {
		smsendsmscode,
		smsimgcode,
		resetloginpassword,
		employeemalllist,
		pcChangePassWord
	} from '@/api/login'

	export default {
		name: 'login',
		data() {

			return {
				loginType: 1,
				loginTypeList: [{
					type: 1,
					label: '重设密码'
				}],

				// 账密
				account: '',
				password: '',
				openPassword: false, //是否明文显示密码
				code: '',
				imgCode: '',
				imgCodeUrl: '',
				second: 0,
				showImgCode: false,
				isCRM: location.origin.indexOf('citta.zmget.com') > -1,
			}
		},
		components: {},
		computed: {
			...mapGetters([
				'loginState',
				'ShopName',
				'ShopLogo',
			])
		},
		watch: {},

		created() {
			this.alimama = process.env.NODE_ENV == 'production' ? (process.env.VUE_APP_CURRENTMODE == 'stage' ?
				'浙ICP备20002894号-4' : '浙ICP备20002894号-5') : '浙ICP备20002894号-4'
		},
		mounted() {},
		methods: {

			//修改密码
			async handleForget() {
				if (!this.validLoginParams()) return;

				let data = {
					SMSCode : this.code,
					LoginPhone: this.account,
					NewPassWord: this.password
				}
				let result = await pcChangePassWord(data)
				if (result.IsSuccess) {
					this.$message({
						showClose: true,
						message: '密码修改成功，请重新登录，1s后自动跳转到登录页面',
						type: 'success'
					});
					setTimeout(()=>{
						this.handleToLogin();
					},1000)
				}

			},
			
			validLoginParams() {
				if (!this.account) {
					this.$message.error('请输入登录手机号')
					return false
				}

				if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(this.account)) {
					this.$message.error('请输入正确的手机号')
					return false
				}
				
				if (!this.code) {
					this.$message.error('请输入短信验证码')
					return false
				}
				
				if (!this.password) {
					this.$message.error('请输入新密码')
					return false
				}
				
				if(this.password.length < 6 || this.password.length >16){
					this.$message.error('请设置6-16位密码')
					return false
				}

				return true;
			},
			
			validPhone(){
				if (!this.account) {
					this.$message.error('请输入登录手机号')
					return false
				}
				
				if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(this.account)) {
					this.$message.error('请输入正确的手机号')
					return false
				}
				
				return true;
			},

			async getSmsCode() {
				
				if (!this.validPhone()) return;
				
				if (this.isSeed) return;
				this.isSeed = true;
				
				try {
					let params = {
						Phone: this.account,
						ImgCode: this.imgCode,
						noError: true,
						Type: 1
					};
					let res = await smsendsmscode(params)
					if (res.IsSuccess) {
						this.$message.success('验证码已发送至您的手机，请注意查收')
						this.second = 59;
						this.showImgCode = false;
						this.imgCode = '';
						this.runTimer();
					} else if (res.ErrorCode == 1000060) {
						this.showImgCode = true;
						this.$message.error(res.Message);
						this.getImgCode();
					} else {
						this.$message.error(res.Message);
						this.getImgCode();
					}
				} catch (err) {
					
				}finally{
					this.isSeed = false;
				}
			},
			async getImgCode() {
				try {
					let data = {
						Phone: this.account
					}
					let res = await smsimgcode(data)
					if (res.img) {
						this.imgCodeUrl = res.img;
					}
				} catch (err) {

				} finally {

				}
			},
			runTimer() {
				if (this.second <= 0) return;
				setTimeout(() => {
					this.second -= 1;
					this.runTimer();
				}, 1000);
			},
			
			
			
			handleToLogin(){
				this.$router.replace({
					path: '/login'
				})
			},
		},
	}
</script>

<style lang="less" scoped>
	.login-page {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		color: #303133;

		.bg {
			display: block;
			width: 100%;
		}
		.bg1{
			position: absolute;
			right: 700px;
			top: 26%;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			
			img{
				display: block;
				width: auto;
				height: auto;
			}
			
			.txt{
				position: absolute;
				right: 240px;
				top: -75px;
				
				.txt1{
					font-size: 58px;
					color: #8B94A6;
				}
				.txt2{
					font-size: 32px;
					color: #9E9E9E;
					margin-top: 20px;
				}
			}
		}
		.bg2{
			position: absolute;
			left: 30px;
			top: 30px;
			width: 161px;
			height: 39px;
		}
		
		.bg3{
			position: absolute;
			left: 210px;
			top: 37px;
		}
		
		
		.login-wraper {
			position: absolute;
			right: 150px;
			top: 50%;
			transform: translateY(-50%);
			width: 550px;
			height: 514px;
			background-color: #fff;
			box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
			border-radius: 6px;

			.login-tabs {
				padding: 40px 50px 30px;
				display: flex;
				align-items: baseline;

				.login-tab {
					font-size: 20px;
					color: #999;
					margin-right: 30px;
					cursor: pointer;
				}

				.current {
					font-size: 26px;
					color: #303133;
				}
			}

			.login-item {
				height: 80px;
				margin: 0 50px;

				display: flex;
				align-items: center;
				overflow: hidden;
				border-bottom: 1px solid #ddd;

				::v-deep input {
					flex: 1 1 auto;
					height: 80px;
					font-size: 16px;
					border: none;
				}

				.icon {
					flex: 0 0 auto;
					width: 28px;
					cursor: pointer;

					img {
						display: block;
						width: 100%;
					}
				}

				.line {
					flex: 0 0 auto;
					width: 1px;
					height: 14px;
					background-color: #ddd;
					margin-left: 10px;
				}

				.forget {
					flex: 0 0 auto;
					padding: 0 10px;
					font-size: 16px;
					color: #409EEF;
					white-space: nowrap;
					cursor: pointer;
				}
			}

			.login-tip {
				display: flex;
				justify-content: center;
				color: #606266;
				font-size: 16px;
				margin-top: 30px;
			}
		}
		
		.login-wraper-crm{
			right: 50%;
			top: 50%;
			transform: translate(50%, -50%);
		}
	}
	
	.form-wraper{
		display: flex;
		align-items: center;
		
		img{
			display: block;
			width: 130px;
			margin-left: 10px;
		}
	}
	.form-tip{
		margin-left: 228px;
		line-height: 20px;
		margin-top: 5px;
	}
</style>
